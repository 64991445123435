import { Footer } from '../../../components/Footer'
import { Grid2Column } from '../../../components/UICore/Grid2Column'
import { Header } from '../../../components/navigation/Header'
import { HeadingRegular } from '../../../components/UICore/HeadingRegular'
import { InsetCardContact } from '../../../components/InsetCardContact'
import { ParagraphBasic } from '../../../components/UICore/ParagraphBasic'
import { RichText } from '../../../components/UICore/RichText'
import { Spacer } from '../../../components/Spacer'
import { SubpageInfo } from '../../../components/SubpageInfo'
import { containerContentStyle, theme } from '../../../theme'
import { css } from '@emotion/css'
import { urls } from '../../../urls'
import ImageGallery from 'react-image-gallery'
import d1 from './d1.png'
import d2 from './d2.png'
import d3 from './d3.png'
import d4 from './d4.png'
import d5 from './d5.png'
import d6 from './d6.png'
import d7 from './d7.png'

const styles = {
  headingExtraLarge: css`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 80px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.03em;
    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      font-size: 52px;
    }
    @media screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 42px;
    }
  `,
  linkStyle: css`
    color: ${theme.colors.black};
  `,
  blockquoteStyle: css`
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 0;
    margin-right: 0;
    padding: 40px;
    border: 1px solid ${theme.colors.line};
    border-radius: 12px;
    background-image: linear-gradient(135deg, #ffeded, #ffeedb);
    color: ${theme.colors.black};
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
  `,
}

const thumbnailWidthHeight = 400

const images = [
  {
    original: d1,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: d2,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: d3,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: d4,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: d5,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: d6,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: d7,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
]

export const Dnai = () => {
  return (
    <div>
      <Header />
      <SubpageInfo
        title='Company Website, DNAi&nbsp;s.r.o'
        description='This project was realized for the company DNAi. They wanted to create a new website for their company. The main goal was to create a website that would be easy to use and would be able to provide the user with loads of information about their projects.'
        client='DNAi s.r.o'
        type='Design, Implementation'
        year='2023'
        prevPageUrl={urls.website.root}
      />
      <div className={containerContentStyle}>
        <HeadingRegular>Showcase of the website</HeadingRegular>
        <Spacer height={32} />
        <ImageGallery
          items={images}
          showPlayButton={false}
          disableThumbnailScroll={true}
          showNav={false}
          showFullscreenButton={false}
          autoPlay={true}
          slideInterval={6000}
          lazyLoad={true}
        />
        <Spacer height={64} />
        <Grid2Column>
          <HeadingRegular>About the website</HeadingRegular>

          <RichText>
            <div className={styles.blockquoteStyle}>
              Website can be found on the following link:&nbsp;
              <a
                className={styles.linkStyle}
                href='https://dnai.ai/'
                target='_blank'
                rel='noreferrer'
              >
                dnai.ai
              </a>
            </div>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              The most challenging part of the project was to work incredibly fast. This website was
              created to present the company at the big stage to companies in Korea and Singapur. I
              had to work incredibly with extremely tight time budget.
            </ParagraphBasic>

            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              During this project I mostly worked with Figma for design. I implemented the design in
              Wordpress with Elementor plugin.
            </ParagraphBasic>
          </RichText>
        </Grid2Column>
      </div>
      <InsetCardContact />
      <Footer />
    </div>
  )
}
