import { Footer } from '../../components/Footer'
import { Grid2Column } from '../../components/UICore/Grid2Column'
import { Header } from '../../components/navigation/Header'
import { InsetCardContact } from '../../components/InsetCardContact'
import { Link } from 'react-router-dom'
import { ProjectLinkCard } from '../../components/ProjectLinkCard'
import { Spacer } from '../../components/Spacer'
import { containerContentStyle, theme } from '../../theme'
import { css } from '@emotion/css'
import { urls } from '../../urls'
import React from 'react'
import advokat from './pics/advokat.png'
import dnai from './pics/dnai1.png'
import maonenergy from './pics/maonenergy.png'
import student from './pics/zeptejtesestudenta.png'
import te from './pics/2te.png'
import tekies from './pics/tekies1.png'

const styles = {
  headingExtraLarge: css`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 80px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.03em;
    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      font-size: 52px;
    }
    @media screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 42px;
    }
  `,
  linkStyle: css`
    color: ${theme.colors.black};
  `,
  linksListStyle: css`
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-count: 2;
    column-gap: 24px;
    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      column-count: 1;
    }
  `,
  btnStyle: css`
    color: ${theme.colors.black};
    margin-right: 6px;
    margin-left: 6px;
    padding: 6px 14px;
    font-size: ${theme.fontsize.menu};
    line-height: ${theme.lineheight.menu};
    font-weight: 600;
    border: none;
    border-radius: 8px;
    background-color: ${theme.colors.backgGrey};
    cursor: pointer;

    :hover {
      background-color: ${theme.colors.backgGrey};
      opacity: 0.8;
    }
  `,
  leftColumnStyle: css`
    display: flex;
    flex-direction: column;
  `,
}

export const PresentationWebsites = () => {
  return (
    <div>
      <Header />
      <div className={containerContentStyle}>
        <Grid2Column>
          <div className={styles.leftColumnStyle}>
            <h1 className={styles.headingExtraLarge}>Website Design&nbsp;& Implementation</h1>
            <Spacer height={16} />
            <Link to={urls.root}>
              <button className={styles.btnStyle}>Back</button>
            </Link>
            <Spacer height={64} />
          </div>
          <div>{}</div>
        </Grid2Column>
        <div className={styles.linksListStyle}>
          <ProjectLinkCard
            urlTo={urls.website.dnai}
            title='DNAi s.r.o.'
            type='Company Presentation Website'
            coverImage={dnai}
          />
          <ProjectLinkCard
            urlTo={urls.website.students}
            title='ZeptejteSeStudenta.cz'
            type='Company Presentation Website'
            coverImage={student}
          />
          <ProjectLinkCard
            urlTo={urls.website.maon}
            title='Maongroup a.s.'
            type='Business website, MaonEnergy'
            coverImage={maonenergy}
          />
          <ProjectLinkCard
            urlTo={urls.website.tekies}
            title='Student website, Tekies s.r.o'
            type='Career website for students'
            coverImage={tekies}
          />
          <ProjectLinkCard
            urlTo={urls.website.advokat}
            title='JUDr. Petr Bauer, advokát'
            type='Presentation Website'
            coverImage={advokat}
          />
          <ProjectLinkCard
            urlTo={urls.website.torkoniak}
            title='2T Engineering'
            type='Presentation Website'
            coverImage={te}
          />
        </div>
      </div>
      <InsetCardContact />
      <Footer />
    </div>
  )
}
