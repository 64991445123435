import bike from './pictures/bike.png'
import city from './pictures/city.png'
import garden from './pictures/lavender.png'
import grass from './pictures/grass.png'
import hockey from './pictures/hockey.png'
import metro from './pictures/metro.png'
import tokyo from './pictures/tokyo.png'
import work from './pictures/work.png'

export const images = [bike, city, garden, grass, hockey, metro, tokyo, work]
