import { Blockquote } from '../../../components/Blockquote'
import { Footer } from '../../../components/Footer'
import { Grid2Column } from '../../../components/UICore/Grid2Column'
import { Header } from '../../../components/navigation/Header'
import { HeadingRegular } from '../../../components/UICore/HeadingRegular'
import { InsetCardContact } from '../../../components/InsetCardContact'
import { ParagraphBasic } from '../../../components/UICore/ParagraphBasic'
import { RichText } from '../../../components/UICore/RichText'
import { Spacer } from '../../../components/Spacer'
import { SubpageInfo } from '../../../components/SubpageInfo'
import { containerContentStyle, theme } from '../../../theme'
import { css } from '@emotion/css'
import { urls } from '../../../urls'
import ImageGallery from 'react-image-gallery'
import tek1 from './tek1.png'
import tek2 from './tek2.png'
import tek3 from './tek3.png'
import tek4 from './tek4.png'
import tek5 from './tek5.png'

const styles = {
  headingExtraLarge: css`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 80px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.03em;
    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      font-size: 52px;
    }
    @media screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 42px;
    }
  `,
  linkStyle: css`
    color: ${theme.colors.black};
  `,
}

const thumbnailWidthHeight = 400

const images = [
  {
    original: tek1,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: tek2,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: tek3,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: tek4,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: tek5,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
]

export const StudentTekies = () => {
  return (
    <div>
      <Header />
      <SubpageInfo
        title='Students page, Tekies'
        description='This project was realized for the company Tekies. They wanted to create a new website for their company to attract students. The main goal was to create a website that would be easy to use and would be able to provide the user with all the necessary information. They also wanted another place to publish their articles and job offers.'
        client='Tekies'
        type='Design, Implementation'
        year='2023'
        prevPageUrl={urls.website.root}
      />
      <div className={containerContentStyle}>
        <HeadingRegular>Showcase of the website</HeadingRegular>
        <Spacer height={32} />
        <ImageGallery
          items={images}
          showPlayButton={false}
          disableThumbnailScroll={true}
          showNav={false}
          showFullscreenButton={false}
          autoPlay={true}
          slideInterval={6000}
          lazyLoad={true}
        />
        <Spacer height={64} />
        <Grid2Column>
          <HeadingRegular>About the website</HeadingRegular>

          <RichText>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              Website can be found on the following link:&nbsp;
              <a
                className={styles.linkStyle}
                href='https://student.tekies.eu/'
                target='_blank'
                rel='noreferrer'
              >
                https://student.tekies.eu/
              </a>
              .
            </ParagraphBasic>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              The most challenging part of the project was to stay in tune with the current design
              guidelines, but also create a new fresh website to attract students. The page can also
              be accessed from the main page&nbsp;
              <a
                className={styles.linkStyle}
                href='https://tekies.eu/'
                target='_blank'
                rel='noreferrer'
              >
                https://tekies.eu/.
              </a>
            </ParagraphBasic>
            <Blockquote text={'Main goal of the project was to deliver ASAP.'}></Blockquote>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              During this project I mostly worked with Figma for design. I implemented the design in
              Wordpress with Elementor plugin.
            </ParagraphBasic>
          </RichText>
        </Grid2Column>
      </div>
      <InsetCardContact />
      <Footer />
    </div>
  )
}
