import { Footer } from '../../../components/Footer'
import { Grid2Column } from '../../../components/UICore/Grid2Column'
import { Header } from '../../../components/navigation/Header'
import { HeadingRegular } from '../../../components/UICore/HeadingRegular'
import { InsetCardContact } from '../../../components/InsetCardContact'
import { RichText } from '../../../components/UICore/RichText'
import { Spacer } from '../../../components/Spacer'
import { SubpageInfo } from '../../../components/SubpageInfo'
import { containerContentStyle, theme } from '../../../theme'
import { css } from '@emotion/css'
import { urls } from '../../../urls'
import ImageGallery from 'react-image-gallery'
import m1 from './m1.png'
import m2 from './m2.png'
import m3 from './m3.png'
import m4 from './m4.png'

const styles = {
  headingExtraLarge: css`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 80px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.03em;
    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      font-size: 52px;
    }
    @media screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 42px;
    }
  `,
  linkStyle: css`
    color: ${theme.colors.black};
  `,
  blockquoteStyle: css`
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 0;
    margin-right: 0;
    padding: 40px;
    border: 1px solid ${theme.colors.line};
    border-radius: 12px;
    background-image: linear-gradient(135deg, #ffeded, #ffeedb);
    color: ${theme.colors.black};
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
  `,
}

const thumbnailWidthHeight = 400

const images = [
  {
    original: m1,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: m2,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: m3,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: m4,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
]

export const MaonEnergy = () => {
  return (
    <div>
      <Header />
      <SubpageInfo
        title='Company Website, MaonEnergy'
        description='This project was realized for company MaonGroup. They wanted a new website to present their energy saving solutions.'
        client='MaonGroup'
        type='Presentation Website'
        year='2022'
        prevPageUrl={urls.website.root}
      />
      <div className={containerContentStyle}>
        <HeadingRegular>Showcase of the website</HeadingRegular>
        <Spacer height={32} />
        <ImageGallery
          items={images}
          showPlayButton={false}
          disableThumbnailScroll={true}
          showNav={false}
          showFullscreenButton={false}
          autoPlay={true}
          slideInterval={6000}
          lazyLoad={true}
        />
        <Spacer height={64} />
        <Grid2Column>
          <HeadingRegular>About the website</HeadingRegular>

          <RichText>
            <div className={styles.blockquoteStyle}>
              Website can be found on the following link:&nbsp;
              <a
                className={styles.linkStyle}
                href='https://maonenergy.cz/'
                target='_blank'
                rel='noreferrer'
              >
                maonenergy.cz
              </a>
            </div>
          </RichText>
        </Grid2Column>
      </div>
      <InsetCardContact />
      <Footer />
    </div>
  )
}
