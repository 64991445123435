import { Blockquote } from '../../../components/Blockquote'
import { Footer } from '../../../components/Footer'
import { Grid2Column } from '../../../components/UICore/Grid2Column'
import { H2 } from '../../../components/UICore/H2'
import { Header } from '../../../components/navigation/Header'
import { HeadingRegular } from '../../../components/UICore/HeadingRegular'
import { ParagraphBasic } from '../../../components/UICore/ParagraphBasic'
import { ProjectWithCodeFigma } from '../../../components/ProjectWithCodeFigma'
import { RichText } from '../../../components/UICore/RichText'
import { Spacer } from '../../../components/Spacer'
import { containerContentStyle, theme } from '../../../theme'
import { css } from '@emotion/css'
import { urls } from '../../../urls'
import ImageGallery from 'react-image-gallery'
import React from 'react'
import inf1 from './inf1.png'
import inf10 from './inf10.png'
import inf11 from './inf11.png'
import inf12 from './inf12.png'
import inf13 from './inf13.png'
import inf14 from './inf14.png'
import inf15 from './inf15.png'
import inf16 from './inf16.png'
import inf17 from './inf17.png'
import inf18 from './inf18.png'
import inf19 from './inf19.png'
import inf2 from './inf2.png'
import inf20 from './inf20.png'
import inf21 from './inf21.png'
import inf3 from './inf3.png'
import inf4 from './inf4.png'
import inf5 from './inf5.png'
import inf6 from './inf6.png'
import inf7 from './inf7.png'
import inf8 from './inf8.png'
import inf9 from './inf9.png'

const styles = {
  linkStyle: css`
    color: ${theme.colors.black};
  `,
}

const thumbnailWidthHeight = 400

const images = [
  {
    original: inf1,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf2,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf3,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf4,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf5,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf6,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf7,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf8,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf9,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf10,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf11,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf12,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf13,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf14,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf15,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf16,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf17,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf18,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf19,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf20,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
  {
    original: inf21,
    thumbnailHeight: thumbnailWidthHeight,
    thumbnailWidth: thumbnailWidthHeight,
  },
]

export const Infora = () => {
  return (
    <div>
      <Header />
      <ProjectWithCodeFigma
        title='Infora'
        description='This project was created as a semestral project for the subject PDA - Priciples of designing mobile applications at CTU in Prague. The goal of the project was to design and create a functioning mobile app. This project was created in collaboration with Pavel Sušický. This project is mainly Frontend application, although there is some python development while using cron jobs to get news and pictures from public API. Interesting thing about this app is that its self-sustainable on the Supabase free plan, so anyone can install Expo Go on their iPhone and check out our app (QR is on the github).;)'
        client='Czech Technical University in Prague'
        type='Semestral project'
        year='2022'
        code={urls.github.school.infora}
        prevPageUrl={urls.school.root}
        figma={urls.figma.infora}
      />

      <div
        className={css`
          ${containerContentStyle}
          padding-top: 0;
        `}
      >
        <Spacer height={32} />
        <HeadingRegular>Showcase of the App</HeadingRegular>
        <Spacer height={32} />
        <ImageGallery
          items={images}
          showPlayButton={false}
          disableThumbnailScroll={false}
          showNav={true}
          showFullscreenButton={false}
          autoPlay={true}
          slideInterval={3000}
          lazyLoad={true}
        />
        <Spacer height={32} />
        <Grid2Column>
          <HeadingRegular>About the App</HeadingRegular>

          <RichText>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              Infora is a news aggregator app, that collects daily news. Users can choose their
              interests to see the news tailored to their liking. They also have the option to
              swipe-right in the news feed to get prompted to share their location. Then the user
              will see the news base on his location (currently working only in GB, GER and CZ).
              Users also can save, share and interact more ways with the news articles. Another
              functionality of the app is the &quot;Explore page&quot;, where users can see news
              from all of the interests.
            </ParagraphBasic>
            <H2 customStyle={{ marginBottom: '32px', marginTop: '32px' }}>
              Architecture description
            </H2>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              Selected PaaS and technologies (mobile apps): React Native (Development primarily
              targeted at iOS, but the app is also functional on Android), TypeScript, Expo platform
              for app development, distribution
            </ParagraphBasic>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              Selected PaaS and technologies (Back-end): Supabase (alternative to Firebase) for
              PostgreSQL powered back-end, Python for scraper script, GitHub Actions (cron job for
              data scraping)
            </ParagraphBasic>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              Selected SaaS: NewsData API (for collecting articles and then storing them in our
              database)
            </ParagraphBasic>
            <Blockquote
              text={
                'The project was a great experience for me. It was my first time developing a mobile app and experiencing everything that comes with it.'
              }
            />
            <H2 customStyle={{ marginBottom: '32px', marginTop: '32px' }}>What I learned</H2>
            <ParagraphBasic customStyle={{ marginBottom: '32px' }}>
              I learned a lot about designing and implementing mobile apps. I have encountered
              numerous problems that are specific to native mobile development and i have learned
              how to deal with them. I have also learned that getting images in a reasonable quality
              from an API is not an easy thing to do.
            </ParagraphBasic>
          </RichText>
        </Grid2Column>
      </div>
      <Footer />
    </div>
  )
}
